import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import KatexFormulaComponent from '../KatexFormulaComponent/KatexFormulaComponent';
import { useMediaQuery } from 'react-responsive';
import TooltipComponent from '../TootipComponent/TooltipComponent';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;   
    justify-content: left;
`;

const RadioGroup = styled.div`
    display: flex;
    justify-content: space-between;   
`;

const RadioWrapper = styled.div`
   display: flex;
   justify-content: left;  
`;

const StyledPrompt = styled.div` 
    ${(props) => {
        if(props.$required) {
            return css`
            display: flex;
            .textWrapper:last-child::after {
                content: "*";
                color: red;
                margin-left: .5em;
            }
            `
        } else {
            return css`
                display: flex;
            `
        }
        
    }};
`;

const RequiredSymbol = styled.div`
    color: red;
    margin: 0;
    padding-left: .2em;
`;
const StyledAppend = styled.div`
    padding-bottom : 32px;
    display: flex;
`;
const PromptWrapper = styled.div`
    color: ${ props => props.theme.label };
    font-weight: 600; 
`;
const PromptContainer = styled.div`
    display: flex;  
`;
const StyledPadding = styled.div`
    padding-top: 12px;
`;
const TooltipWrapper = styled.div`  
    display: flex;   
`;

const MultipleRadiobuttonComponent = (props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const dispatch = useDispatch();
    if(!props.question)
      {return (<div></div>);}

    const handleChange = (questionOptionID) => {
        const selectedVal = props.question.options.filter((v, i) => {
            return v.questionOptionID === parseInt(questionOptionID);
        })[0].value;

        const currentVal = props.question.answer;

        const newVal = selectedVal === currentVal ? "" : selectedVal;

        if(props.onChange){
            props.onChange(newVal);
        }
        
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newVal} });
    };

    const getPrompts = (prompts) =>{
       
        if(!prompts){
          return(<div></div>)
        }
        let orderedPrompts = prompts.sort((a, b) => (a.order > b.order) ? 1 : -1)

        return(
          orderedPrompts.map((prompt, i) =>{
            if(prompt.valueType === 'img'){
              return ( 
                     <PromptWrapper key={'prompt_'+i}>
                        <StyledPrompt className='textWrapper' style={{justifyContent: isSmallScreen ? 'center' : 'left'}}>{prompt.value}</StyledPrompt>
                        <img alt={prompt.description} src={imgBasePath+prompt.url} style={{ height:130, width:150 }} />
                     </PromptWrapper> 
                    );       
            }else if(prompt.valueType === 'katex'){
                    return (
                            <KatexFormulaComponent className='textWrapper' key={'prompt_'+i} value={prompt.value} />
                            );       
            }else {
              return ( 
                       <PromptContainer key={'prompt_'+i}>                         
                          <PromptWrapper className='textWrapper' key={'prompt_w_'+i} variant='body1'
                                  dangerouslySetInnerHTML={{__html: prompt.value}} >                 
                          </PromptWrapper> 
                          { prompt.tooltip &&
                          <TooltipWrapper>
                             <TooltipComponent tooltip={prompt.tooltip} />
                          </TooltipWrapper>
                          }
                      </PromptContainer>
                        
                    );
            }
            
          })
        );
      }
     
    const getLabel = (index, isChecked, opt,imgOption) => {
        const val = opt.value;
       if(imgOption){
        return(
        <span style={{ fontSize: '.8rem' }}> {val} <img alt={opt.description} src = {imgBasePath+opt.optionsUrl} width ='200px' height ='80px'></img> </span>
        )
       }
       else{
        return (<span style={{ fontSize: '.8rem'}} dangerouslySetInnerHTML={{__html: val}}></span>)
       }
    }
    
    const getListItems = () => {         
        let orderedOptions = props.question.options.sort((a, b) => (a.order > b.order) ? 1 : -1);
        console.log(orderedOptions);

        const listElements = orderedOptions.map((option, i) => {
                const isChecked = props.question.answer === option.value;  
         
                return(
                    <RadioWrapper key={option.questionOptionID + '_wrapper'}>
                        <RadioButtonComponent 
                           key={option.questionOptionID + '_button'} 
                           onChange={handleChange} 
                           id={option.questionOptionID} 
                           group={props.question.questionID} 
                           checked={isChecked}
                           label = {option.optionsUrl ? getLabel(i, isChecked, option, true) : getLabel(i, isChecked, option, false)}
                        />                        
                    </RadioWrapper>
                )
        })
        return listElements;
    }
 
    return (
        <StyledRoot>
            <StyledAppend>
                <StyledPrompt $required={props.required}>
                     {getPrompts(props.question.prompts)}
                </StyledPrompt>
            </StyledAppend>
            <RadioGroup style={{justifyContent: isSmallScreen ? 'space-around' : 'space-between', maxWidth: (props.question.options.length === 2 && !isSmallScreen) ? '120px' : ''}}>
              {getListItems()}
            </RadioGroup>
        </StyledRoot>
    );
}

export default MultipleRadiobuttonComponent;