import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PrimaryFormComponent from '../../components/RegistrationComponent/PrimaryFormComponent';
import configs from '../../configuration/configuration.json';
import MobilePrimaryFormComponent from '../../components/RegistrationComponent/MobilePrimaryFormComponent';
import { getClientBaseUrl } from '../../util/helper/Helper';

const stateList = configs['default'].states;

const RegistrationPrimaryFormContainer = (props) => {

  let primaryFormProps = { ...props };

  const dispatch = useDispatch();

  //** Validators **//
  const validateAboutUs = primaryFormProps.validateAboutUs = (ab)=>{    
    return true;
  }

  const validateFirstName = primaryFormProps.validateFirstName = (val) => {
    let validFirstName = val.length >= 1 && val.length <= 255 && val.trim() !== '';
    return validFirstName;
  };

  const validateLastName = primaryFormProps.validateLastName = (val) => {
    let validLastName = val.length >= 1 && val.length <= 255 && val.trim() !== '';
    return validLastName;
  };

  const validateZipcode = primaryFormProps.validateZipCode = (zipcode) => {
    let validZip = /^\d{5}$|^\d{5}-\d{4}$/.test(zipcode);
    return validZip;
  };

  const validateState = primaryFormProps.validateState = (v) => {
    let validState = !!(v && stateList[v]);
    return validState;
  }

  const validateAddress = primaryFormProps.validateAddress = (val) => {
    let validAddress = (val == null ? false : val.length >= 2);
    return validAddress;
  }

  const validateCity = primaryFormProps.validateCity = (val) => {
    let validCity = val == null ? false : val.length >= 2
    return validCity;
  }

  const validatePhoneNumber = primaryFormProps.validatePhoneNumber = (val) => {
    let cleanInput = val ? val.replaceAll('-', '') : "";
    return /^\d{10}$/.test(cleanInput);
  }
  
  const validateExtNumber = primaryFormProps.validateExtNumber = (val) => {
    let cleanInput = val ? val.replaceAll('-', '') : "";
    return /^\d{10}$/.test(cleanInput);
  }

  const validateNumber = (input) => {

    if (input.length < 4) {
      return /^\d+$/.test(input);
    }
    else if (input.length < 8) {
      return /^\d{3}-\d*$/.test(input);
    }
    else if (input.length > 7) {
      return /^\d{3}-\d{3}-\d{4}$/.test(input);
    }
    else {
      return false;
    }
  }

  const getIsValid = primaryFormProps.getIsValid = () => {
    const fnameValid = validateFirstName(firstName);
    const lnameValid = validateLastName(lastName);
    const zipValid = validateZipcode(zipcode);
    const stateValid = validateState(state);
    const addressValid = validateAddress(address);
    const cityValid = validateCity(city);
    const phoneIsValid = validatePhoneNumber(phone);
    // const extIsValid = validateExtNumber(ext);

    return fnameValid && lnameValid && zipValid && stateValid && phoneIsValid && addressValid && cityValid;
  }

  //** Field values **//
  const [firstName, setFname] = useState(props.firstName);
  const [lastName, setLname] = useState(props.lastName);
  const [aboutUs, setAboutUs] = useState(props.aboutUs);
  const [zipcode, setZipcode] = useState(props.zipcode);
  const [address, setAddress] = useState(props.address);
  const [apt, setApt] = useState(props.apt);
  const [city, setCity] = useState(props.city);
  const [state, setState] = useState(props.state);
  const [phone, setPhone] = useState(props.phone);
  const [ext, setExt] = useState(props.ext);

  //** Field validation **//
  const [isValidFname, setValidFname] = useState(props.firstName ? validateFirstName(props.firstName) : null);
  const [isValidLname, setValidLname] = useState(props.lastName ? validateLastName(props.lastName) : null);
  const [isValidAboutUs, setValidAboutUs] = useState(null);
  const [isValidZip, setValidZip] = useState(props.zipcode ? validateZipcode(props.zipcode) : null);
  const [isValidState, setValidState] = useState(props.state ? validateState(props.state) : null);
  const [isValidAddress, setValidAddress] = useState(props.address ? validateAddress(props.address) : null);
  const [isValidCity, setValidCity] = useState(props.city ? validateCity(props.city) : null);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(props.phone ? validatePhoneNumber(props.phone) : null);
  const [isValidExtNumber, setIsValidExtNumber] = useState(props.ext ? validateExtNumber(props.ext) : null);
  const [isValid, setIsValid] = useState(null);

  primaryFormProps.firstName = firstName;
  primaryFormProps.lastName = lastName;
  primaryFormProps.aboutUs = aboutUs;
  primaryFormProps.zipcode = zipcode;
  primaryFormProps.address = address;
  primaryFormProps.apt = apt;
  primaryFormProps.city = city;
  primaryFormProps.state = state;
  primaryFormProps.ext = ext;
  primaryFormProps.phone = phone;
  primaryFormProps.isValidFname = isValidFname;
  primaryFormProps.isValidLname = isValidLname;
  primaryFormProps.isValidAboutUs = isValidAboutUs;
  primaryFormProps.isValidZip = isValidZip;
  primaryFormProps.isValidAddress = isValidAddress;
  primaryFormProps.isValidCity = isValidCity;
  primaryFormProps.isValidState = isValidState;
  primaryFormProps.isValidPhone = isValidPhoneNumber;
  primaryFormProps.isValidExt = isValidExtNumber;
  primaryFormProps.isValid = isValid;

  //** Change Handlers **//    
  primaryFormProps.onAptChange = (v) => {
    setApt(v);
  }

  const cityId = 'city-input';

  primaryFormProps.onCityChange = (v) => {
    setCity(v);
    // update validation if the change event is triggered by autofill (document.activeElement.id !== <id>)
    // update validation on each change after the validation has been set at least once - don't spam validation message until the user or autofill has completed an entry
    if (document.activeElement.id !== cityId || isValidCity !== null) {
      setValidCity(validateCity(v));
    }
  }

  const stateId = 'state-input';

  primaryFormProps.onStateChange = (v) => {
    v = v.toUpperCase();
    if (/^[a-zA-Z]*$/.test(v)) {
      setState(v.length > 2 ? v.slice(0, 2) : v);
      if (document.activeElement.id !== stateId || isValidState !== null) {
        setValidState(validateState(v.length > 2 ? v.slice(0, 2) : v));
      }
    }
  }

  const addrId = 'address-input';

  primaryFormProps.onAddressChange = (v) => {
    setAddress(v);
    if (document.activeElement.id !== addrId || isValidAddress !== null) {
      setValidAddress(validateAddress(v));
    }
  }

  const fnameId = 'fname-input';

  primaryFormProps.onFnameChange = (v) => {
    if (primaryFormProps.globalState.cognitoUser && primaryFormProps.globalState.cognitoUser.given_name) {
      return
    }
    setFname(v);
    if (document.activeElement.id === fnameId || isValidFname !== null) {
      setValidFname(validateFirstName(v));
    }
  };

  const zipId = 'zip-input';

  primaryFormProps.onZipcodeChange = (v) => {
    setZipcode(v);
    if (!document.activeElement.id === zipId || isValidZip !== null) {
      setValidZip(validateZipcode(v));
    }
  };

  const lnameId = 'lname-input';

  primaryFormProps.onLnameChange = (v) => {
    if (primaryFormProps.globalState.cognitoUser && primaryFormProps.globalState.cognitoUser.family_name) {
      return
    }
    setLname(v);
    if (document.activeElement.id !== lnameId || isValidLname !== null) {
      setValidLname(validateLastName(v));
    }
  };

  primaryFormProps.onAboutUsChange = (v) =>{
    setValidAboutUs(validateAboutUs(v));
    setAboutUs(v);
  }

  const phoneId = 'phone-input';

  primaryFormProps.onPhoneChange = (v) => {
    if (!validateNumber(v)) {

      let cleanedInput = v.replace(/\D/g, '');

      if (cleanedInput.length > 3) {
        cleanedInput = cleanedInput.replace(/.{3}/, '$&-');
      }
      if (cleanedInput.length > 7 && cleanedInput.length < 13) {
        cleanedInput = cleanedInput.replace(/.{7}/, '$&-');
      }

      setPhone(cleanedInput.slice(0, 12));
      if (document.activeElement.id !== phoneId || isValidPhoneNumber !== null) {
        setIsValidPhoneNumber(validatePhoneNumber(cleanedInput.slice(0, 12)));
      }
    }
    else {
      setPhone(v);
      if (document.activeElement.id !== phoneId || isValidPhoneNumber !== null) {
        setIsValidPhoneNumber(validatePhoneNumber(v));
      }
    }
  }

  const extId = 'ext-input';

  primaryFormProps.onExtChange = (v) => {
    setExt(v);
    if (document.activeElement.id !== extId || isValidExtNumber !== null) {
      setIsValidExtNumber(validateExtNumber(v));
    }
  }

  //** Key Handlers **//
  primaryFormProps.handleOnKeyDown = (e) => {
    if (isValid && (e.key === "Enter")) {
      primaryFormProps.handleSubmit(e);
    }
  }

  //** Click Handlers **//
  primaryFormProps.handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      console.log('IS VALID');
      props.onClickSubmit({ firstName, lastName, aboutUs, address, phone, apt, city, state, zipcode });
    } else {
      console.log('NOT VALID');
    }
  }

  //** Blur Handlers **//
  primaryFormProps.onLnameBlur = (v) => {
    setValidLname(validateLastName(v));
  }

  primaryFormProps.onFnameBlur = (v) => {
    setValidFname(validateFirstName(v));
  }

  primaryFormProps.onAboutUsChange = (v) =>{
    setValidAboutUs(validateAboutUs(v));
    setAboutUs(v);
  }

  primaryFormProps.onAddressBlur = (v) => {
    setValidAddress(validateAddress(v));
  }

  primaryFormProps.onCityBlur = (v) => {
    setValidCity(validateCity(v));
  }

  primaryFormProps.onStateBlur = (v) => {
    setValidState(validateState(v));
  }

  primaryFormProps.onZipCodeBlur = (v) => {
    setValidZip(validateZipcode(v));
  }
  
  primaryFormProps.onExtBlur = (v) => {
    setIsValidExtNumber(validateExtNumber(v));
  }

  primaryFormProps.onPhoneBlur = (v) => {
    const IsInputValid = validatePhoneNumber(v);
    setIsValidPhoneNumber(IsInputValid);

    if (IsInputValid) {
      setPhone(v.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
  }

  useEffect(() => {
    setFname(props.firstName);
    if (props.firstName) {
      setValidFname(validateFirstName(props.firstName));
    }
  }, [props.firstName])

  useEffect(() => {
    setLname(props.lastName);
    if (props.lastName) {
      setValidLname(validateLastName(props.lastName));
    }
  }, [props.lastName])

  useEffect(() => {
    setPhone(props.phone);
    if (props.phone) {
      setIsValidPhoneNumber(validatePhoneNumber(props.phone));
    }
  }, [props.phone])

  useEffect(() => {
    setState(props.state);
    if (props.state) {
      setValidState(validateState(props.state));
    }
  }, [props.state])

  useEffect(() => {
    setAddress(props.address);
    if (props.address) {
      setValidAddress(validateAddress(props.address));
    }
  }, [props.address])

  useEffect(() => {
    setCity(props.city);
    if (props.city) {
      setValidCity(validateCity(props.city));
    }
  }, [props.city])

  useEffect(() => {
    setZipcode(props.zipcode);
    if (props.zipcode) {
      setValidCity(validateZipcode(props.zipcode));
    }
  }, [props.zipcode])

  useEffect(() => {
    setIsValid(getIsValid());
  }, [firstName, lastName, aboutUs, address, phone, ext, apt, city, state, zipcode, primaryFormProps.globalState.cognitoUser, props.userExists])

  useEffect(() => {
    scrollToTop();
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }


  if (props.isSmallScreen) {
    return (
      <MobilePrimaryFormComponent {...primaryFormProps}></MobilePrimaryFormComponent>
    )
  } else {
    return (
      <PrimaryFormComponent {...primaryFormProps}></PrimaryFormComponent>
    )
  }
}

export default RegistrationPrimaryFormContainer;
